// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("packs/js/a-vendor.bundle.base")
require("packs/js/b-off-canvas")
require("packs/js/c-hoverable-collapse")
require("packs/js/d-template")
require("packs/js/e-settings")
require("packs/js/f-todolist")

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

require("datatables.net-bs4");
require("datatables.net-responsive-bs4");
require("datatables.net-select-bs4");
require("jquery-datetimepicker")
require("select2")
require("bootstrap/js/dist/modal")
require("bootstrap/js/dist/popover")
require('cocoon-js')
require('inputmask/dist/inputmask')
require('inputmask/dist/jquery.inputmask')
require('inputmask/dist/bindings/inputmask.binding')
require('daterangepicker')
require('fullcalendar')
require('fullcalendar/dist/locale/id')

import moment from 'moment'
window.moment = moment
import toastr from 'toastr'
window.toastr = toastr
import Tree from '@widgetjs/tree'
window.Tree = Tree
import Chart from 'chart.js/auto';
global.Chart = Chart;
import Swal from 'sweetalert2'
window.Swal = Swal

const Tagify = window.Tagify = require('@yaireo/tagify');

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

$(document).ready(function() {
    $(document).on("focus", ".integer-only", function() {
        $(this).inputmask({ alias: 'numeric', groupSeparator: ',', digits: 0, removeMaskOnSubmit: true });
    });

    $(document).on("blur", ".integer-only", function() {
        if ($(this).val().length == 0) { $(this).val(0) }
    })

    $(document).on("focus", ".two-decimal", function() {
        $(this).inputmask({ alias: 'numeric', groupSeparator: '.', digits: 2, removeMaskOnSubmit: true });
    });

    $(document).on("blur", ".two-decimal", function() {
        if ($(this).val().length == 0) { $(this).val(0) }
    })

    $(".integer-only").trigger("focus");
    $(".two-decimal").trigger("focus");

    $(".select2").select2({
        theme: "bootstrap4",
        width: "100%",
    });

    $('.datetimepicker').datetimepicker({
        format: 'd/m/Y H:i'
    });

    $('.daterangepicker-single-no-time').daterangepicker({
        opens: 'right',
        autoApply: true,
        autoUpdateInput: false,
        singleDatePicker: true,
        showDropdowns: true,
        locale: {
            format: 'DD-MM-YYYY',
            cancelLabel: 'Clear'
        },
        startDate: moment(),
    }).on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    }).on('apply.daterangepicker', function(ev, picker) {
        var value = picker.startDate.format('DD-MM-YYYY');
        $(this).val(value);
    });

    $('.daterangepicker-single-with-time').daterangepicker({
        opens: 'right',
        autoApply: true,
        autoUpdateInput: false,
        singleDatePicker: true,
        timePicker: true,
        timePicker24Hour: true,
        locale: {
            format: 'DD-MM-YYYY HH:mm',
            cancelLabel: 'Clear'
        },
        startDate: moment(),
    }).on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    }).on('apply.daterangepicker', function(ev, picker) {
        var value = picker.startDate.format('DD-MM-YYYY HH:mm');
        $(this).val(value);
    });

    $(".navbar-toggler").on("click", function() {
        if ($(this).hasClass("collapsed")) {
            $(this).find(".mdi").removeClass("mdi-menu");
            $(this).find(".mdi").addClass("mdi-close");
        } else {
            $(this).find(".mdi").addClass("mdi-menu");
            $(this).find(".mdi").removeClass("mdi-close");
        }
    });

    $(document).on("keypress", ".form-no-enter input[type=text]", function(e) {
        if (e.keyCode == 13) {
            return false;
        }
    });

    $("a.remove_fields").first().hide();

    /* $(".money").inputmask({ alias: 'numeric', groupSeparator: '.', digits: 2, removeMaskOnSubmit: true });

    $(".integer-only").inputmask({ alias: 'numeric', groupSeparator: '.', digits: 0, removeMaskOnSubmit: true }); */

    $("a.remove_fields.always-show-remove").first().show();

    $(".field_with_errors input:not([readonly]), .field_with_errors select").addClass("is-invalid");

    $(document).on("change", ".field_with_errors input, .field_with_errors select", function(e) {
        $(this).removeClass("is-invalid");
    });

    $('form').find('input').filter(':input:visible:enabled:not([readonly], .datetimepicker, [class*="daterangepicker-"]):first').focus();

    // function for copy
    var $copyText = $('.copy-text')
    $copyText.append("<a class='pl-1 text-primary btn-copy'><i class='far fa-copy'></i><a>")
    var $copyValue = $('.copy-value')
    $copyValue.append("<a class='pl-1 text-primary btn-copy'><i class='fas fa-copy'></i><a>")

    $('.btn-copy').tooltip({
        animation: true,
        title: "Click to copy"
    });

    $copyText.on('click', function () {
        copyToClipboard($(this).text().trim())
        $(this).find('.btn-copy').attr('data-original-title', 'Copied').tooltip('show');
    })
    $copyValue.on('click', function () {
        copyToClipboard($(this).val())
        $(this).find('.btn-copy').attr('data-original-title', 'Copied').tooltip('show');
    })

    $('.btn-copy').on('mouseleave', function(){
        $(this).attr('data-original-title', 'Click to copy')
    })

    function copyToClipboard(text) {
        var sampleTextarea = document.createElement("textarea");
        document.body.appendChild(sampleTextarea);
        sampleTextarea.value = text; //save main text in it
        sampleTextarea.select(); //select textarea contenrs
        document.execCommand("copy");
        document.body.removeChild(sampleTextarea);
    }
});

var changed = false;

(function($, window, document, undefined) {
    $(document).ready(function() {
        $('.detect').on("change paste keyup", function() {
            changed = true;
        });

        $("a").not(".ignore-detect-link").on("click", function(e) {
            if (changed) {
                if (!confirm("There is some change inside this page, are you sure want to leave this page without saving?")) {
                    e.preventDefault();
                }
            }
        });

        $(document).on("focus", "input[type=text]", function(e) {
            this.select();
        });
    });
})(window.jQuery, window, document);

$(function() {
    $('[data-toggle="popover"]').popover({
        html: true,
        sanitize: false,
    })
})

// Check Connection
var loadStatus = true;
$(window).on('load', function () {
    loadStatus = true;
})
testConnectionStatus();
function testConnectionStatus() {
    var socket = new WebSocket('ws://' + window.location.host + '/cable');
    socket.onopen = function (e) {
        connectStatusAlert(false)
        loadStatus = true;
    }
    socket.onclose = function (e) {
        if (loadStatus == false) {
            connectStatusAlert(true)
        }
        loadStatus = false;
        reConnectStatus()
    }
}
function reConnectStatus() {
    testConnectionStatus();
}
var showSwalert = false;
let swalert;
function connectStatusAlert(status) {
    if (showSwalert == false && status == true) {
        swalert = Swal.fire({
            icon: 'error',
            title: 'Koneksi Terputus',
            text: 'Periksa koneksi Anda dan tunggu hingga stabil kembali',
            showConfirmButton: false,
            allowOutsideClick: false
        })
    } else if (showSwalert == true && status == false) {
        swalert.close()
        Swal.fire({
            icon: 'success',
            title: 'Koneksi Terhubung',
            showConfirmButton: false,
            timer: 1500
        })
    }
    showSwalert = status;
}
